import {graphql} from 'gatsby'
import React from 'react'

import Container from '../components/Container'
import GalleryWrapper from '../components/GalleryWrapper'
import SectionHeader from '../components/SectionHeader'
import styled from 'styled-components'
import SEO from '../components/SEO'

const GalleryStyles = styled.section`
  text-align: center;
`

const GalleryPage = ({data}: any) => {
  return (
    <>
      <SEO
        title="Gallery"
        description="Gallery page for Smith Cottages Langport"
      />
      <GalleryStyles>
        <Container>
          <>
            <SectionHeader>Gallery</SectionHeader>
            <GalleryWrapper {...data} />
          </>
        </Container>
      </GalleryStyles>
    </>
  )
}

export const query = graphql`
  query ImagesForGalleryPage {
    allFile(filter: {relativeDirectory: {eq: "gallery"}}) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(quality: 64, maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default GalleryPage
